<template>
<div class="bgm" style="overflow-y:scroll;overflow-x:hidden;height:100%">
    <div class="over_bgc">
        <!-- 顶部栏 -->
        <top-banner theme="white" @backHome="backHome"></top-banner>
        <!-- 详情 -->
        <div class="main x_between">
            <!-- 左边 -->
            <div class="left" style="flex:1">
                <img src="@/assets/image/home/sixPage/BIMOM.jpg" class="poster">
                <div class="column">
                    <div style="color:#fff;margin-top:30px">{{title}}</div>
                    <div style="color:#797979">日期：{{(date)}}  作者：{{author}}</div>
                    <div class="back" @click="backPage">返回列表</div>
                </div>
            </div>
            <!-- 右边 -->
            <div class="right" style="flex:2">
                <div class="content" >{{content}}</div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import topBanner from "@/components/topBanner.vue"
export default {
    name: 'twoPage',
    data(){
        return {
            title:"《悟空&展昭科技隐私条款》",
            date:"2022-04-23",
            author:"成都悟空和展昭科技有限公司",
            content:"一、【注册与账号】\n" +
                "成都悟空和展昭科技有限公司（以下简称悟空&展昭科技）重视用户的隐私。您在使用我们的服务时，我们可能会收集和使用您的相关信息。我们希望通过本《隐私条款》向您说明，在使用我们的服务时，我们如何收集、使用、储存和分享这些信息，以及我们为您提供的访问、更新、控制和保护这些信息的方式。本《隐私条款》与您所使用的悟空&展昭科技服务息息相关，希望您仔细阅读，在需要时，按照本《隐私条款》的指引，作出您认为适当的选择。本《隐私条款》中涉及的相关技术词汇，我们尽量以简明扼要的表述，并提供进一步说明的链接，以便您的理解。 您使用或继续使用我们的服务，即意味着您同意我们按照本《隐私条款》收集、使用、储存和分享您的相关信息。\n" +
                "一、【您提供的信息】\n" +
                "1.1 您在注册账号或使用我们的服务时，向我们提供的相关个人信息，例如电话号码、电子邮件等；\n" +
                "1.2 您通过我们的服务向其他方提供的共享信息，以及您使用我们的服务时所储存的信息；\n" +
                "1.3 其他方分享的您的信息；\n" +
                "1.4 其他方使用我们的服务时所提供有关您的共享信息。\n" +
                "二、【我们可能收集的信息】\n" +
                "2.1 日志信息。当您使用悟空&展昭科技服务时，服务器会自动记录一些信息，例如您对我们服务的使用情况、IP地址、所访问服务的URL、浏览器的类型和使用的语言以及访问日期和时间等。\n" +
                "2.2 设备信息。某些产品和/或服务包含唯一应用程序编号。当您安装、激活、更新、卸载相关服务或当这些服务定期与悟空&展昭科技通信（例如软件的更新）时，系统会将此编号以及与安装相关的信息（例如操作系统类型和应用程序版本号）发送给悟空&展昭科技。\n" +
                "(1) 当您使用具有定位功能的悟空&展昭科技服务时，系统会自动处理有关设备的位置信息，以使得您不需要手动输入自身地理坐标就可获得相关服务。\n" +
                "(2) 为了对不同型号的设备提供更好的服务与体验改善，悟空&展昭科技会记录硬件型号、操作系统版本号、手机的国际移动设备身份码（IMEI）、手机的网络设备的硬件地址（MAC）等信息。\n" +
                "2.3 Cookie和匿名标示符等工具。Cookie主要的功能是便于您使用网站产品和/或服务，以及帮助网站统计独立访客数量等。运用cookie技术，悟空&展昭科技能够为您提供更加周到的个性化服务，并允许您设定您特定的服务选项。\n" +
                "(1) 当您使用悟空&展昭科技产品或服务时，会向您的设备发送Cookie。当您与我们提供给合作伙伴的服务（例如广告和/或推广服务，以及可能显示在其他网站上的由悟空&展昭科技提供的服务功能）进行交互时，我们允许Cookie或者匿名标识符发送给悟空&展昭科技服务器。\n" +
                "(2) 您可以选择拒绝 Cookie。您可以通过修改浏览器设置的方式拒绝Cookie。如果您选择拒绝Cookie，则您可能无法登录或使用依赖于Cookie 的悟空&展昭科技服务或功能。\n" +
                "(3) 如果您不希望在您访问悟空&展昭科技网站时，悟空&展昭科技基于cookie向您提供个性化的推广信息，可以通过个性化配置限制悟空&展昭科技对cookie的使用。\n" +
                "2.4 以上数据信息都采用匿名的方式。同时，我们也会对信息采取加密处理，保证信息的安全性。\n" +
                "三、【我们可能如何使用信息】\n" +
                "3.1 通过使用收集的信息，悟空&展昭科技会得以向您提供个性化的服务。上述信息还可以帮助悟空&展昭科技改进悟空&展昭科技的产品或服务，并用于开发新的产品或服务。\n" +
                "3.2 悟空&展昭科技会以高度的勤勉义务对待这些信息，除非事先获得您的授权或本声明另有规定外，不会将这些信息对外公开或向第三方提供（悟空&展昭科技关联公司除外）。\n" +
                "3.3 悟空&展昭科技可能会与合作伙伴共同向您提供您所要求的服务或者共同向您展示您可能感兴趣的内容。在信息为该项产品/服务所必须的情况下，您同意悟空&展昭科技可与其分享必要的信息。并且，悟空&展昭科技会要求其确保数据安全并且禁止用于任何其他用途。除此之外，悟空&展昭科技不会向任何无关第三方提供或分享信息。\n" +
                "3.4 悟空&展昭科技可能会对产品使用情况进行统计。同时，悟空&展昭科技可能会与公众分享这些统计信息，以展示我们服务的整体使用趋势。这些统计信息不包含您的任何身份识别信息。\n" +
                "3.5 在如下情况下，悟空&展昭科技可能会披露您的信息：\n" +
                "（1）事先获得您的授权；\n" +
                "（2）您使用共享功能；\n" +
                "（3）根据法律、法规、法律程序的要求或政府主管部门的强制性要求；\n" +
                "（4）以学术研究或公共利益为目的；\n" +
                "（5）为维护悟空&展昭科技的合法权益，例如查找、预防、处理欺诈或安全方面的问题；\n" +
                "（6）符合相关服务条款或使用协议的规定。\n" +
                "四、【我们可能分享的信息】\n" +
                "除以下情形外，未经您同意，我们以及我们的关联公司不会与任何第三方分享您的个人信息：\n" +
                "我们以及我们的关联公司，可能将您的个人信息与我们的关联公司、合作伙伴及第三方服务供应商、承包商及代理（例如代表我们发出电子邮件或推送通知的通讯服务提供商、为我们提供位置数据的地图服务供应商）分享（他们可能并非位于您所在的法域），用作下列用途：\n" +
                "• 向您提供我们的服务；\n" +
                "• 实现“我们可能如何使用信息”部分所述目的；\n" +
                "• 履行我们在《悟空&展昭科技服务协议》或本《隐私条款》中的义务和行使我们的权利；\n" +
                "• 理解、维护和改善我们的服务。\n" +
                "• 如我们或我们的关联公司与任何上述第三方分享您的个人信息，我们将努力确保该等第三方在使用您的个人信息时遵守本《隐私条款》及我们要求其遵守的其他适当的保密和安全措施。\n" +
                "随着我们业务的持续发展，我们以及我们的关联公司有可能进行合并、收购、资产转让或类似的交易，您的个人信息有可能作为此类交易的一部分而被转移，我们将在转移前通知您。\n" +
                "我们或我们的关联公司还可能为以下需要而保留、保存或披露您的个人信息：\n" +
                "• 遵守适用的法律法规；\n" +
                "• 遵守法院命令或其他法律程序的规定；\n" +
                "• 遵守相关政府机关的要求；\n" +
                "• 为遵守适用的法律法规、维护社会公共利益，或保护我们的客户、我们或我们的集团公司、其他用户或雇员的人身和财产安全或合法权益所合理必需的用途。\n" +
                "五、【信息安全】\n" +
                "5.1 我们仅在本《隐私条款》所述目的所必需的期间和法律法规要求的时限内保留您的个人信息。\n" +
                "5.2 我们使用各种安全技术和程序，以防信息的丢失、不当使用、未经授权阅览或披露。例如，在某些服务中，我们将利用加密技术（例如SSL）来保护您提供的个人信息。但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全。您需要了解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。\n" +
                "5.3 您在使用我们的服务时，我们可能使用您的信息向您的设备发送电子邮件、新闻或推送通知。\n" +
                "5.4 我们可能在必要时（例如因系统维护而暂停某一项服务时）向您发出与服务有关的公告。您可能无法取消这些与服务有关、性质不属于推广的公告。\n" +
                "六、【隐私条款的适用范围】\n" +
                "6.1 除某些特定服务外，我们所有的服务均适用本《隐私条款》。这些特定服务将适用特定的隐私条款。针对某些特定服务的特定隐私条款，将更具体地说明我们在该等服务中如何使用您的信息。该特定服务的隐私条款构成本《隐私条款》的一部分。如相关特定服务的隐私条款与本《隐私条款》有不一致之处，适用该特定服务的隐私条款。\n" +
                "6.2 除本《隐私条款》另有规定外，本《隐私条款》所用词语将与《悟空&展昭科技服务协议》所定义的词语具有相同的涵义。\n" +
                "6.3 请您注意，本《隐私条款》不适用于以下情况：\n" +
                "• 通过我们的服务而接入的第三方服务（包括任何第三方网站）收集的信息；\n" +
                "• 通过在我们服务中进行广告服务的其他公司或机构所收集的信息。\n" +
                "七、【条款变更】\n" +
                "我们可能适时修订本《隐私条款》的条款，该等修订构成本《隐私条款》的一部分。如该等修订造成您在本《隐私条款》下权利的实质减少，我们将在修订生效前通过在主页上显著位置提示或向您发送电子邮件或以其他方式通知您。在该种情况下，若您继续使用我们的服务，即表示同意受经修订的本《隐私条款》的约束。\n" +
                "\n",
            url:""
            }
    },
    components:{
        topBanner
    },
    mounted(){
        //this.title= this.$route.query.title;
        //this.findDynamics();

    },
    methods:{
        formatDate1(data){
            let dt = new Date(data)
            return dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate()
        },
        backPage(){
            this.$router.go(-1)
        },
        backHome(){
            this.$router.push("/")
        },
        findDynamics(){
            let config = {
                headers : {
                    'Content-Type':'application/json'
                }
            };
            const param={
                title:this.title,
            };
            this.axios.post(this.VUE_APP_BASE_API+'/CompanyDynamics/getDynamic',param,config).then((res)=>{
                if(res.data.resultCode==0){
                    this.tabledatas=[];
                    res.data.resultData.forEach((val)=>{
                        let data={
                            title:val.title,
                            author:val.author,
                            content:val.content,
                            date:val.date
                        }
                        this.tabledatas.push(data);
                    });
                    this.title=this.tabledatas[0].title;
                    this.author=this.tabledatas[0].author;
                    this.content=this.tabledatas[0].content;
                    this.date=this.tabledatas[0].date;
                    this.url="http://www.wkhzz.com:8085/BimomWebSource/"+this.title+".png";
                }else{
                    alert(res.data.resultMsg);
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.bgm{
  width: 100%;
  height: 100vh;
  background: url("../assets/image/home/sixPage/bgm.jpg") no-repeat;
  background-size: cover;
  overflow: hidden;
  .over_bgc{
    width: 100%;
    min-height: 100vh;
    background: rgba($color: #000000, $alpha: .7);
    padding: 50px 70px !important;
  }
}
.main{
    margin-top: 60px;
    .left{
        font-family: '幼圆 Bold', '幼圆 Regular', '幼圆', sans-serif;
        font-weight: bold;
        font-size: 22px;
        color: #FFFFFF;
        margin-top: 30px;
        position: relative;
        .poster{
            width: 100%;
            border-radius: 10px;
        }
        .column{
            div{
                margin-top: 40px;
            }
            .back{
                position: absolute;
                bottom: 0;
                cursor: pointer;
                color:#797979;
                &:hover{
                    color: #02A7F0;
                }
            }
        }
        
    }
    .right{
        width: 100%;
        height: 750px;
        overflow: auto;
        .content{
            color: #fff;
            font-family: '幼圆', sans-serif;
            padding: 0 40px;
            font-size: 22px;
            line-height: 30px;
            white-space: pre-wrap;
        }
    }
}

</style>
